import React from 'react';
import OwlCarousel from "react-owl-carousel";
import lazrakLogo from "../assets/img/brand/lazrak.png";
import ColasLogo from "../assets/img/brand/Colas_logo.png";
import emEnergie from "../assets/img/brand/emEnergie.png";
import hiolle from "../assets/img/brand/hiolle.png";
import Exprom from "../assets/img/brand/Exprom.png";
import LPF from "../assets/img/brand/LPF.png";

export default function BrandHome() {
  const responsiveOptions = {
    0: {
      items: 2, // 1 item for screens 0px and up
    },
    480: {
      items: 3, // 2 items for screens 480px and up
    },
    768: {
      items: 4, // 3 items for screens 768px and up
    },
    1024: {
      items: 5, // 4 items for screens 1024px and up
    },
    1200: {
      items: 6, // 6 items for screens 1200px and up
    },
  };
  return (
    <>
      <div className="brand-area">
        <div className="container">
          <OwlCarousel
            dots={false}
            margin={20}
            responsive={responsiveOptions}
            className="brand kon-carousel owl-theme">
            <div className="brand-box">
              <img className="img-responsive" src={lazrakLogo} alt="Lazrak"/>
            </div>
            <div className="brand-box">
              <img className="img-responsive" src={ColasLogo} alt="colas"/>
            </div>
            <div className="brand-box">
              <img className="img-responsive" src={emEnergie} alt="emEnergie"/>
            </div>
            <div className="brand-box">
              <img className="img-responsive" src={hiolle} alt="hiolle"/>
            </div>
            <div className="brand-box">
              <img className="img-responsive" src={Exprom} alt="Exprom"/>
            </div>
            <div className="brand-box">
              <img className="img-responsive" src={LPF} alt="LPF"/>
            </div>
          </OwlCarousel>
        </div>
      </div>
    </>
  )
}