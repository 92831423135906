/* global jQuery */

import React, { useEffect } from "react";
import $ from 'jquery';
import logo from "../assets/img/logo.png"; // Ajustez si nécessaire

/* global $ */ // Pour éviter l'erreur ESLint concernant l'utilisation de '$'

export default function Header() {
  useEffect(() => {
    if ($.fn.meanmenu) {
      console.log('MeanMenu plugin is loaded');
      $("#dropdown").meanmenu({
        meanMenuContainer: '.mobile-menu-area',
        meanScreenWidth: "767",
      });
    } else {
      console.log('MeanMenu plugin is not loaded');
    }
  }, []);

  
  return(
    <>
      <header>
        <div className="header-style1-area">
          <div className="header-top-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-6">
                  <div className="header-top-left">
                    <ul>
                      <li><i className="fa fa-phone" aria-hidden="true"></i><a href="tel:+1234567890">(212) 05 22 898 917</a></li>
                      <li><i className="fa fa-envelope" aria-hidden="true"></i><a href="#">rps.officiel@gmail.com</a></li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                  <div className="header-top-right">
                    <ul>
                      <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                      <li><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                      <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                      <li><a href="#"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-header-area" id="sticker">
            <div className="container">
              <div className="row">
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                  <div className="logo-area">
                    <a href="index.html"><img className="img-responsive" src={logo} alt="logo" /></a>
                  </div>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                  <div className="main-menu-area">
                    <nav>
                      <ul>
                        <li className="active"><a href="#">Accueil</a>
                          {/*<ul>*/}
                          {/*  <li><a className="active" href="index.html">Home 1</a></li>*/}
                          {/*  <li><a href="index1.html">Home 2</a></li>*/}
                          {/*  <li><a href="index2.html">Home 3</a></li>*/}
                          {/*  <li><a href="index3.html">Home 4</a></li>*/}
                          {/*</ul>*/}
                        </li>
                        <li><a href="#">RPS</a></li>
                        <li><a href="#">Services</a>
                          {/*<ul>*/}
                          {/*  <li><a href="services1.html">Services 1</a></li>*/}
                          {/*  <li><a href="services2.html">Services 2</a></li>*/}
                          {/*  <li><a href="services3.html">Services 3</a></li>*/}
                          {/*  <li><a href="services-details.html">Services Details</a></li>*/}
                          {/*</ul>*/}
                        </li>
                        <li><a href="#">Projects</a>
                          {/*<ul>*/}
                          {/*  <li><a href="projects1.html">Projects 1</a></li>*/}
                          {/*  <li><a href="projects2.html">Projects 2</a></li>*/}
                          {/*  <li><a href="projects-details.html">Projects Details</a></li>*/}
                          {/*  <li className="has-child-menu"><a href="#">Demo</a>*/}
                          {/*    <ul className="thired-level">*/}
                          {/*      <li><a href="#">Demo 1</a></li>*/}
                          {/*      <li><a href="#">Demo 2</a></li>*/}
                          {/*    </ul>*/}
                          {/*  </li>*/}
                          {/*</ul>*/}
                        </li>
                        {/*<li><a href="#">Produits</a>*/}
                        {/*  <ul>*/}
                        {/*    <li><a href="shop1.html">Shop 1</a></li>*/}
                        {/*    <li><a href="shop2.html">Shop 2</a></li>*/}
                        {/*    <li><a href="shop-details.html">Shop Details</a></li>*/}
                        {/*  </ul>*/}
                        {/*</li>*/}
                        <li><a href="contact.html">Contact</a></li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
           {/*Mobile Menu Area Start*/}
          <div className="mobile-menu-area">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="mobile-menu">
                    <nav id="dropdown">
                      <ul>
                        <li><a href="#">Home</a>
                          <ul>
                            <li><a href="index.html">Home 1</a></li>
                            <li><a href="index1.html">Home 2</a></li>
                            <li><a href="index2.html">Home 3</a></li>
                            <li><a href="index3.html">Home 4</a></li>
                          </ul>
                        </li>
                        <li><a href="about-us.html">About Us</a></li>
                        <li><a href="#">Services</a>
                          <ul>
                            <li><a href="services1.html">Services 1</a></li>
                            <li><a href="services2.html">Services 2</a></li>
                            <li><a href="services3.html">Services 3</a></li>
                            <li><a href="services-details.html">Services Details</a></li>
                          </ul>
                        </li>
                        <li><a href="#">Projects</a>
                          <ul>
                            <li><a href="projects1.html">Projects 1</a></li>
                            <li><a href="projects2.html">Projects 2</a></li>
                            <li><a href="projects-details.html">Projects Details</a></li>
                            <li className="has-child-menu"><a href="#">Demo</a>
                              <ul className="thired-level">
                                <li><a href="#">Demo 1</a></li>
                                <li><a href="#">Demo 2</a></li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li><a href="#">Pages</a>
                          <ul>
                            <li><a href="index.html">Home 1</a></li>
                            <li><a href="index1.html">Home 2</a></li>
                            <li><a href="index2.html">Home 3</a></li>
                            <li><a href="index3.html">Home 4</a></li>
                            <li><a href="about-us.html">About Us</a></li>
                            <li><a href="services1.html">Services 1</a></li>
                            <li><a href="services2.html">Services 2</a></li>
                            <li><a href="services3.html">Services 3</a></li>
                            <li><a href="services-details.html">Services Details</a></li>
                            <li><a href="projects1.html">Projects 1</a></li>
                            <li><a href="projects2.html">Projects 2</a></li>
                            <li><a href="projects-details.html">Projects Details</a></li>
                            <li><a href="shop1.html">Shop 1</a></li>
                            <li><a href="shop2.html">Shop 2</a></li>
                            <li><a href="shop-details.html">Shop Details</a></li>
                            <li><a href="news1.html">News 1</a></li>
                            <li><a href="news1.html">News 2</a></li>
                            <li><a href="news-details.html">News Details</a></li>
                            <li><a href="team1.html">Team 1</a></li>
                            <li><a href="team2.html">Team 2</a></li>
                            <li><a href="team3.html">Team 3</a></li>
                            <li><a href="team-details.html">Team Details</a></li>
                            <li><a href="404.html">404</a></li>
                            <li><a href="contact.html">Contact</a></li>
                          </ul>
                        </li>
                        <li><a href="#">Shop</a>
                          <ul>
                            <li><a href="shop1.html">Shop 1</a></li>
                            <li><a href="shop2.html">Shop 2</a></li>
                            <li><a href="shop-details.html">Shop Details</a></li>
                          </ul>
                        </li>
                        <li><a href="#">News</a>
                          <ul>
                            <li><a href="news1.html">News 1</a></li>
                            <li><a href="news1.html">News 2</a></li>
                            <li><a href="news-details.html">News Details</a></li>
                          </ul>
                        </li>
                        <li><a href="contact.html">Contact</a></li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
           {/*Mobile Menu Area End*/}
        </div>
      </header>
    </>
  )
}