import {Outlet} from 'react-router-dom'
import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import '../styles/normalize.css'
import '../styles/main.css'
import 'bootstrap/dist/css/bootstrap.css'
import '../styles/animate.min.css'
import '../styles/font-awesome.min.css'
import '../styles/font/flaticon.css'
import '../styles/meanmenu.min.css'
import '../styles/style.css'
import '../styles/responsive.css'


export default function Layout() {
  return (
    <>
      <div className="wrapper-area">
        <Header/>
        <Outlet/>
        <Footer/>
       </div>
    </>
  )
}