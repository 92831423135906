import React from 'react';
import Slider from "../components/Slider";
import AboutHome from "../components/AboutHome";
import Quote from "../components/Quote";
import ServicesHome from "../components/ServicesHome";
import BrandHome from "../components/BrandHome";

export default function HomePage(){
  return(
    <>
      <Slider />
   
      {/*<Quote />*/}
      <AboutHome />
      <ServicesHome />
      <BrandHome />
    </>
  )
}