import React from 'react';
 import signaletique from '../assets/img/signaletique1.png'
 import habillage from '../assets/img/habillage-facade.png'
 import enseigne from '../assets/img/enseigne.png'
 import imprision from '../assets/img/imprision.png'
 import stand from '../assets/img/stand1.png'
 import habillageVoiture from '../assets/img/habillage-voiture.png'

export default function ServicesHome() {
  return (
    <>
      <div className="news-3-area section-space-88-64">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="section-title-area">
                <h2 className="section-title">Nos Services</h2>
                <div className="title-bottom-icon">
                  <span className="title-bottom-icon-left"></span>
                  <i className="fa fa-cubes" aria-hidden="true"></i>
                  <span className="title-bottom-icon-right"></span>
                </div>
                <p>
                  Découvrez l'excellence à travers notre service phare, conçu pour répondre aux besoins les plus exigeants
                  de nos clients. Grâce à nos machines de haute qualité et à notre expertise,
                  nous offrons des solutions innovantes et sur mesure qui transforment vos idées en réalité.
                </p>
              </div>
            </div>
          </div>
          <div className="row inner-section-space-top">
            <div className="col-lg-6 col-md-5 col-sm-12 col-xs-12 news-3-area-left">
              <div className="inner-news-3-box-top">
                <img className="img-responsive" src={signaletique} alt="news1"/>
              </div>
              <div className="inner-news-3-box-bottom">
                <h4><a href="#" title="">SIGNALETIQUE</a></h4>
                <p>La signalisation publicitaire est un outil de communication indispensable pour promouvoir votre
                  entreprise ou vos produits. RPS vous propose une large gamme de supports personnalisables pour votre ...</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-7 col-sm-12 col-xs-12 news-3-area-right">
              <div className="row inner-news-3-box-right">
                <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12 margin-b-30">
                  <div className="inner-news-3-box-top fixed-width pull-left">
                   
                    <img className="img-responsive" src={enseigne} alt="news1" />
                  </div>
                  <div className="inner-news-3-box-bottom fixed-width pull-left">
                    <h4><a href="#" title="">ENSEIGNE PUBLICITAIRE</a></h4>
                    <p>L'enseigne joue un rôle crucial dans la communication visuelle d'une entreprise, étant souvent
                      la première impression que...
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12 margin-b-30">
                  <div className="inner-news-3-box-top fixed-width pull-left">
                    <img className="img-responsive" src={habillage} alt="news1"/>
                  </div>
                  <div className="inner-news-3-box-bottom fixed-width pull-left">
                    <h4><a href="#" title="">HABILLAGE FAÇADE</a></h4>
                    <p>Nous offrons une variété de solutions pour l'habillage de vos façades, transformant l'apparence extérieure...</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row inner-section-space-top">
            <div className="col-lg-6 col-md-5 col-sm-12 col-xs-12 news-3-area-left">
              <div className="row inner-news-3-box-right">
                <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12 margin-b-30">
                  <div className="inner-news-3-box-top fixed-width pull-left">
                    <img className="img-responsive" src={habillageVoiture} alt="news1" />
                  </div>
                  <div className="inner-news-3-box-bottom fixed-width pull-left">
                    <h4><a href="#" title="">HABILLAGE VEHICULE</a></h4>
                    <p>Nous offrons des solutions innovantes pour transformer votre flotte automobile en un outil de communication…</p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12 margin-b-30">
                  <div className="inner-news-3-box-top fixed-width pull-left">
                    <img className="img-responsive" src={stand} alt="news1"/>
                  </div>
                  <div className="inner-news-3-box-bottom fixed-width pull-left">
                    <h4><a href="#" title="">STAND D'EXPOSITION</a></h4>
                    <p>Transformez votre présence lors d'événements et de salons professionnels avec nos stands d'exposition sur mesure…</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-7 col-sm-12 col-xs-12 news-3-area-right">
              
              <div className="inner-news-3-box-top">
                <img className="img-responsive" src={imprision} alt="news1"/>
              </div>
              <div className="inner-news-3-box-bottom">
                <h4><a href="#" title="">IMPRESSION NUMERIQUE</a></h4>
                <p>
                  Que ce soit pour des affiches, des bannières, des cartes de visite ou des brochures,
                  Faites confiance à notre expertise pour donner vie à vos projets avec des impressions précises et durables...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}