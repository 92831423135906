import React from "react";
import flickr1 from "../assets/img/flickr/1.jpg"
import flickr2 from "../assets/img/flickr/2.jpg"
import flickr3 from "../assets/img/flickr/3.jpg"
import flickr4 from "../assets/img/flickr/4.jpg"
import flickr5 from "../assets/img/flickr/5.jpg"
import flickr6 from "../assets/img/flickr/6.jpg"

export default function Footer(){
  return(
    <>
      <footer>
        <div className="footer-area-top section-space-b-less-30">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 margin-b-30">
                <h3>À propos de <b>RPS</b></h3>
                <p>est une entreprise leader dans l’industrie publicitaire, spécialisée dans la conception,
                  la réalisation et l'installation d'enseignes publicitaires, d’habillage de façades et de véhicules,
                  ainsi que de panneaux publicitaires, signalétiques et décoratifs.
                </p>
                <ul className="footer-social">
                  <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                  <li><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                  <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                  <li><a href="#"><i className="fa fa-pinterest" aria-hidden="true"></i></a></li>
                  <li><a href="#"><i className="fa fa-rss" aria-hidden="true"></i></a></li>
                  <li><a href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a></li>
                </ul>
              </div>
              
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 margin-b-30">
                <h3>Corporate Office</h3>
                <ul className="corporate-address">
                  <li><i className="fa fa-map-marker" aria-hidden="true"></i>Boulevard Oum errabiaa résidence Ouafa 1 N°4</li>
                  <li><i className="fa fa-phone" aria-hidden="true"></i>(212) 05 22 898 917</li>
                  <li><i className="fa fa-envelope-o" aria-hidden="true"></i>rps.officiel@gmail.com</li>
                  <li><i className="fa fa-fax" aria-hidden="true"></i>whatsapp : (212) 06 66 482 522</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-area-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <p>Copyrights. Tous droits réservés. &nbsp; réaliser par <a
                  href="rps-services.ma" target="_blank">RPS</a></p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}