import React from 'react';
import imAbout from "../assets/img/about/about.jpg";

export default function AboutHome() {
  return (
    <>
      <div className="about-area section-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
              <div className="about-left">
                <h2>Qui nous <span>sommes</span></h2>
                <p>Fondée en 2002, RPS est une entreprise leader dans l’industrie publicitaire, spécialisée dans la conception,
                  la réalisation et l'installation d'enseignes publicitaires, d’habillage de façades et de véhicules,
                  ainsi que de panneaux publicitaires, signalétiques et décoratifs. Avec plus de 20 ans d’expérience,
                  nous avons su nous démarquer par notre professionnalisme et notre qualité de travail incomparable.
                  Avec RPS, vous :
                </p>
                <ul>
                  <li><i className="flaticon-check-mark-button"></i>Bénéficiez de plus de 20 ans d’expérience dans l’industrie publicitaire,
                  </li>
                  <li><i className="flaticon-check-mark-button"></i> Accédez à des solutions publicitaires innovantes et personnalisées
                  </li>
                  <li><i className="flaticon-check-mark-button"></i>Profitez d'un accompagnement complet
                  </li>
                  <li><i className="flaticon-check-mark-button"></i>Travaillez avec une équipe professionnelle et créative</li>
                </ul>
                <a href="#" className="btn-read-more">Lire Plus<i className="fa fa-chevron-right"
                                                                  aria-hidden="true"></i></a>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <div className="about-right img-back-side">
                <div className="overlay"></div>
                <a href="#"><img className="img-responsive" src={imAbout} alt="about" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="about-3-area section-space-100-74">*/}
      {/*  <div className="container">*/}
      {/*    <div className="row">*/}
      {/*      <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">*/}
      {/*        <div className="about-3-left">*/}
      {/*          <div className="about-3-left-top">*/}
      {/*            <h2>Qui nous sommes</h2>*/}
      {/*            <p><span>Koncrete Is A Construction & Industrial Company</span></p>*/}
      {/*            <p>Dorem ipsum dolor sit amet, consectetur adipiscing elit. Ut blandit nisi a dictum tristique. Nullam*/}
      {/*              urna metus, vestibulum ac sodales eSed ut perspiciatis unde omnis iste natus error sit voluptatem*/}
      {/*              accusantium doloremque laudantium totam remrutrumDorem ipsum dolor sit amet, consectetur adipiscing*/}
      {/*              elit. Sed ut perspiciatis.Dorem ipsum dolor sit amet.*/}
      {/*              consectetur adipiscing elit. Ut blandit nisi a dictum tristique. Nullam urna metus, vestibulum ac*/}
      {/*              sodales eSed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque*/}
      {/*              laudantium totam remrutrumDorem ipsum dolor sit amet, consectetur.</p>*/}
      {/*            <ul>*/}
      {/*              <li><i className="flaticon-check-mark-button"></i>Expert material is defined as a substance (most often a solid)</li>*/}
      {/*              <li><i className="flaticon-check-mark-button"></i>Dedicated  material substance (most often a solid)</li>*/}
      {/*              <li><i className="flaticon-check-mark-button"></i>Team designated for you is dedicated to you and all your</li>*/}
      {/*              <li><i className="flaticon-check-mark-button"></i>With every requirement we check latest</li>*/}
      {/*            </ul>*/}
      {/*          </div>*/}
      {/*          <a href="#" className="btn-read-more-fill">Lire plus<i className="fa fa-chevron-right"*/}
      {/*                                                                 aria-hidden="true"></i></a>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">*/}
      {/*        <div className="about-right img-back-side">*/}
      {/*          <a href="#"><img className="img-responsive" src={imAbout} alt="about" /></a>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="row section-space-top">*/}
      {/*      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">*/}
      {/*        <div className="about-3-details">*/}
      {/*          <div className="media">*/}
      {/*            <a className="pull-left" href="#">*/}
      {/*              <img className="media-object" src="img/about/1.png" alt="About-3 1" />*/}
      {/*            </a>*/}
      {/*            <div className="media-body">*/}
      {/*              <h4><a href="#">Impression Numérique - PLV</a></h4>*/}
      {/*              <p>*/}
      {/*                Sed ut perspiciatis unde omnis isteyrty natus errooluptatemcusantiuloremque laudantium totam rem.*/}
      {/*              </p>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">*/}
      {/*        <div className="about-3-details">*/}
      {/*          <div className="media">*/}
      {/*            <a className="pull-left" href="#">*/}
      {/*              <img className="media-object" src="img/about/2.png" alt="About-3 1" />*/}
      {/*            </a>*/}
      {/*            <div className="media-body">*/}
      {/*              <h4><a href="#">SIGNALÉTIQUE</a></h4>*/}
      {/*              <p>Sed ut perspiciatis unde omnis isteyrty natus errooluptatemcusantiuloremque laudantium totam*/}
      {/*                rem.</p>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">*/}
      {/*        <div className="about-3-details">*/}
      {/*          <div className="media">*/}
      {/*            <a className="pull-left" href="#">*/}
      {/*              <img className="media-object" src="img/about/3.png" alt="About-3 1" />*/}
      {/*            </a>*/}
      {/*            <div className="media-body">*/}
      {/*              <h4><a href="#">Enseigne Lmineuse </a></h4>*/}
      {/*              <p>Sed ut perspiciatis unde omnis isteyrty natus errooluptatemcusantiuloremque laudantium totam*/}
      {/*                rem.</p>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  )
}